import React, { useEffect, useState } from 'react';

import { Container, Row, Col, Card, Modal } from 'react-bootstrap';
import Accordion from 'react-bootstrap/Accordion';
import Form from 'react-bootstrap/Form';
import { Controller, useForm } from "react-hook-form";
//import InputMask from "react-input-mask";
import Button from 'react-bootstrap/Button';

import { ErrorMessage } from '@hookform/error-message';
import { useTranslation } from 'react-i18next';

import '../../../../assets/sass/accordion.scss';

import api from '../../../../services/api';
import GroupFilePassengers from '../GroupFilePassengers/GroupFilePassengers';
import GroupFileServices from '../GroupFileServices/GroupFileServices';
import SearchAllAgent from '../../../../components/C2Points/GroupFile/SearchAllAgent';
import ModalContentPayment from '../ModalContentPayment/ModalContentPayment';

export interface propAccountsForm {
    action: any,
    info: any
};

function GroupFileForm({
    action, info
}: propAccountsForm) {

    const { t } = useTranslation();

    const today = new Date();
    const todayStr = `${today.getFullYear()}-${today.getMonth()+1}-${today.getDate()}`;

    const tourAuth: any = JSON.parse(localStorage.getItem('c2tourAuth') || '{}');

    const userName: any = tourAuth.user.nome;
    const userLastName: any = tourAuth.user.lastName;
    const uId: any = tourAuth.user.uId;
    const userId: any = tourAuth.user.id;
    const [customerType, setCustomerType] = useState<any>(info ? info.customerType : 1);
    const [operatorId,] = useState<any>(info ? info.operatorId : userId);
    const [operatorName,] = useState<any>(info ? `${info.operatorUser.name} ${info.operatorUser.lastName}` : `${userName} ${userLastName}`);
    const [clientIdentity, setClientIdentity] = useState<any>(info ? info.clientIdentity : "");
    const [groupName, setGroupName] = useState<any>(info ? info.groupName : "");

    const [tarType, setTarType] = useState<any>(info ? info.tarType : 1);
    const [comission, setComission] = useState<any>(info ? formatValue(info.comission) : "");
    const [markup, setMarkup] = useState<any>(info ? formatValue(info.markup) : "");

    const [dateCreated, ] = useState<any>(info ? info.dateCreated : todayStr);
    const [firstServiceDate, setFirstServiceDate] = useState<any>(info ? info.firstServiceDate : "");
    const [currency, setCurrency] = useState<any>(info ? info.currency : "BRL");
    
    const [fileNo, setFileNo] = useState<any>(info ? info.fileNo : '');
    const [operationStatus, ] = useState<any>(info ? info.operationStatus : 1);
    const [financialStatus, ] = useState<any>(info ? info.financialStatus : 1);

    const [arrayPassengers, setArrayPassengers] = useState<any>(info ? info.passengers : 
        []//[{id:-1,name:'',passportId:'',email:'',phone:''}]
    );
    const [passengersDeleted, setPassengersDeleted] = useState<any>([]);

    const [arrayServices, setArrayServices] = useState<any>(info ? info.services : 
        []//[{id:-1,type:'6',resourceId:'',productCode:'',supplierId:'',globalPrice:'1',netAmount:'',saleAmount:'',serviceDate:'',city:'',language:'1',status:'1'}]
    );
    const [servicesDeleted, setServicesDeleted] = useState<any>([]);
    
    const [totalReceive, setTotalReceive] = useState<any>(info ? info.totalReceive : 0);
    const [totalPay, setTotalPay] = useState<any>(info ? info.totalPay : 0);
    const [profitAmount, setProfitAmount] = useState<any>(info ? info.profitAmount : 0);
    const [profitTax, setProfitTax] = useState<any>(info ? info.profitTax : 0);

    const [invoiceType, setInvoiceType] = useState<any>(info ? info.invoiceType : 1);

    const [totalAmount, setTotalAmount] = useState<any>(info ? formatValue(info.totalAmount) : "");

    const [modalResponse, setModalResponse] = useState<any>(null);
    const [checkoutResponse, setCheckoutResponse] = useState<any>('');
    const [modalContent, setModalContent] = useState<any>('loading');
    const [disableButton, setDisableButton] = useState<boolean>(false);

    const {
        control,
        handleSubmit,
        formState: { errors },
    } = useForm();

    const strToFloat = (str: string): any => {
        if(!str || str === '') return 0.0;
        str = '' + str;
        return parseFloat(str.replace(/[^0-9,]*/g, '').replace(',', '.'));
      }
      const strToInt = (str: string): any => {
        if(!str || str === '') return 0;
        return parseInt(str);
      }

    const onSubmit = (dataObj: any) => {
        var route = '';
        
        const comissionF = parseFloat(comission ? comission.replace(',', '.') : 0);
        const markupF = parseFloat(markup ? markup.replace(',', '.') : 0);
        const totalAmountF = parseFloat(totalAmount ? totalAmount.replace(',', '.') : 0);

        const servicesObj: any = [];

        for(let i = 0; i < arrayServices.length; i++) {
            const serviceObj = {
                id: arrayServices[i].id,
                type: arrayServices[i].type,
                resourceId: arrayServices[i].resourceId ? arrayServices[i].resourceId : 0,
                productCode: arrayServices[i].productCode,
                supplierId: arrayServices[i].supplierId,
                globalPrice: arrayServices[i].globalPrice,
                netAmount: strToFloat(arrayServices[i].netAmount),
                saleAmount: strToFloat(arrayServices[i].saleAmount),
                serviceDate: arrayServices[i].serviceDate,
                city: arrayServices[i].city,
                language: arrayServices[i].language,
                status: arrayServices[i].status
            }
            servicesObj.push(serviceObj);
        }

        var params = {
            id: info ? info.id : 0,
            customerType: customerType,
            operatorId: operatorId,
            clientIdentity: clientIdentity,
            groupName: groupName,
            tarType: tarType,
            comission: comissionF,
            markup: markupF,
            dateCreated: dateCreated,
            firstServiceDate: firstServiceDate,
            currency: currency,
            fileNo: info ? info.fileNo : '',
            
            invoiceType: invoiceType,
            totalAmount: totalAmountF,

            passengers: arrayPassengers,
            services: servicesObj,
            passengersDeleted: passengersDeleted,
            servicesDeleted: servicesDeleted,
            payments: [
                {
                    payMethod: 111
                }
            ]
        };

        //TODO
        console.log(params, "objeto da conta");
        //return;

        setDisableButton(true);
        setModalResponse(true);
        setModalContent("loading");

        if (action === 1) {
            //console.log('insert');
            route = 'CreateGroupFile';
        } else {
            //console.log('edit');
            route = 'EditGroupFile';
        }

        const token = localStorage.getItem('GroupId') || '{}';
        const config = {
            headers: { 'Authorization': `Bearer ${token}` },
        };

        const EditAccounts = async () => {
            try {

                const { data } = await api.post(`GroupFile/${route}`, params, config);
                /* console.log(data.data); */
                if (data.status !== 400) {
                    var response = data.data;

                    setCheckoutResponse(data);

                    if(response.log === 1) {
                        setModalContent("error");
                    } else {
                        setFileNo(response.data.fileNo);

                        
                        setModalContent("success");
                    }
                    // response.data.paymentLink

                    //alert(response.texto);
                    //if (response.log !== 1) {
                    //    window.location.href = window.location.origin + '/list-groups-customized';
                    //} 
                }
            } catch (error: any) {
                // if (error?.response?.status === 401) {
                //     window.location.href = window.location.origin + '/';
                // }
                // if (error?.response?.status === 400) {
                //     window.location.href = window.location.origin + '/list-groups-customized';
                // }
            }
        }
        EditAccounts();
    };

    function resetForm() {
        // TODO 
    }

    const getPassengers = (objPosDevices: any, deletedObjects: any) => {

        setArrayPassengers(objPosDevices);

        setPassengersDeleted(deletedObjects);
    }

    const getServices = (objPosDevices: any, deletedObjects: any) => {

        setArrayServices(objPosDevices);

        setServicesDeleted(deletedObjects);

        updateFirstServiceDate(objPosDevices);

        calcTotals(objPosDevices);
    }

    function updateFirstServiceDate(services: any) {
        var temp = firstServiceDate;

        for(var i = 0; i < services.length; i++) {
            if(temp === '' || (services[i].serviceDate !== '' && strToDate(services[i].serviceDate) < strToDate(temp))) {
                temp = services[i].serviceDate;
            }
        }
        if(temp !== firstServiceDate) setFirstServiceDate(temp);

    }

    function strToDate(cell: any): any {
        if(cell === '') return null;
        var aux: any = cell.split("T")[0].split("-");

        return new Date(parseInt(aux[0]), parseInt(aux[1]), parseInt(aux[2]));
    }
    
    function formatDate(cell: any) {
        if (!cell) return '';
        //var date = convertUTCDateToLocalDate(new Date(cell));
        var aux: any = cell.split("T")[0].split("-");
        var date: any = `${aux[2]}/${aux[1]}/${aux[0]}`;
        return date;
    }

    function formatValue(cell: any) {
        //console.log(Math.sign(cell))
        if(cell && cell !== '') {
            return cell.toFixed(2).replace(".", ",");
        } else {
            return '0,00';
        }
    }

    async function createNewFileNumber() {
        try {
            const token = localStorage.getItem('GroupId') || '{}';
            const config = {
                headers: { 'Authorization': `Bearer ${token}` },
            };

            const params = {
                serviceDate: firstServiceDate
            };

            const { data } = await api.post(`GroupFile/CreateNewGroupFileNumber`, params, config);
            /* console.log(data.data); */
            if (data.status !== 400) {
                var response = data.data;

                if(response.log !== 0) {
                    alert(response.texto);
                } else {
                    setFileNo(response.data);
                }
            }
        } catch (error: any) {
            if (error?.response?.status === 401) {
                window.location.href = window.location.origin + '/';
            }
            if (error?.response?.status === 400) {
                window.location.href = window.location.origin + '/list-groups-customized';
            }
        }
    }

    function getOperationStatusText(statusCode: any) {
        var statusStr = "";
        switch(statusCode) {
            case 1: statusStr = "quote"; break;
            case 2: statusStr = "bookingsRequested"; break;
            case 3: statusStr = "bookingsConfirmed"; break;
            case 4: statusStr = "partialPayment"; break;
            case 5: statusStr = "inOperation"; break;
            case 6: statusStr = "operated"; break;
            case 7: statusStr = "canceled"; break;
            default: return "";
        }
        return t(`groupsAndCustomized.operationStatusOpts.${statusStr}`);
    }

    function getFinancialStatusText(statusCode: any) {
        var statusStr = "";
        switch(statusCode) {
            case 1: statusStr = "pending"; break;
            case 2: statusStr = "invoiceSent"; break;
            case 3: statusStr = "partialReceived"; break;
            case 4: statusStr = "fullReceived"; break;
            default: return "";
        }
        return t(`groupsAndCustomized.financialStatusOpts.${statusStr}`);
    }

    function calcTotals(services: any) {
        var totalR = 0;
        var totalP = 0;
        var totalPax = arrayPassengers.length;

        for(var i = 0; i < services.length; i++) {
            var multi = 1;
            if(services[i].globalPrice === '2') {
                multi = totalPax;
            }
            totalP += parseFloat(services[i].netAmount ? services[i].netAmount.replace(',', '.') : 0) * multi;
            totalR += parseFloat(services[i].saleAmount ? services[i].saleAmount.replace(',', '.') : 0) * multi;
        }

        setTotalReceive(totalR);
        setTotalPay(totalP);
        setProfitAmount(totalR - totalP);
        setProfitTax(totalR ? ((totalR - totalP) / totalR) * 100 : 0);
    }

    // useEffect(() => {
    //     if(fileNo === '' && firstServiceDate && action === 1) {
    //         createNewFileNumber();
    //     }
    // },[action]);

    return (
        <>
            <Card className="bg-white mt-4">
                <Accordion >
                    <Card.Body>
                        <Container className="p-0 mt-3" fluid>
                            <Form noValidate validated={false} onSubmit={handleSubmit(onSubmit)}>
                                <Row className="mb-3">
                                    <Form.Group as={Col} md={6}>
                                        <Controller
                                            control={control}
                                            name="customerType"
                                            //rules={{ required: { value: true, message: "required" }, }}
                                            render={({ field }: any) => (
                                                <div className='d-flex align-items-center' style={{ height: "86px" }}>
                                                    <Form.Check
                                                        {...field}
                                                        type="radio"
                                                        label={t("groupsAndCustomized.customerType.registeredCustomer")}
                                                        // name="customerType"
                                                        id="customerTypeRadios1"
                                                        onClick={() => { setCustomerType(1) }}
                                                        checked={customerType === 1}
                                                        className="col-md-6"
                                                    />
                                                    <Form.Check
                                                        {...field}
                                                        type="radio"
                                                        label={t("groupsAndCustomized.customerType.quickFile")}
                                                        // name="customerType"
                                                        id="customerTypeRadios3"
                                                        onClick={() => { setCustomerType(3) }}
                                                        checked={customerType === 3}
                                                        className="col-md-6"
                                                    />
                                                </div>
                                            )}
                                        />
                                    </Form.Group>
                                    <Form.Group as={Col} md={6}>
                                        <Form.Label>{t("groupsAndCustomized.operator")}</Form.Label>
                                        <p>{operatorName}</p>
                                    </Form.Group>
                                </Row>    
                                <Row className="mb-3">
                                    <Form.Group as={Col} md={6} controlId="formGridSupplier">
                                        <Form.Label>{t("groupsAndCustomized.client")}</Form.Label>
                                        <Controller
                                            control={control}
                                            name="clientIdentity"
                                            rules={{ required: { value: !clientIdentity, message: "required" }, }}
                                            render={({ field }: any) => (
                                                <SearchAllAgent
                                                    //propsField={field}
                                                    //propsErrors={errors}
                                                    //propsLabel="Cliente"
                                                    setAgent={(agent: any) => { setClientIdentity(agent.code); setTarType(agent.tarType); }}
                                                />
                                            )}
                                        />
                                        <ErrorMessage
                                            errors={errors}
                                            name="clientIdentity"
                                            render={({ message }) => <small style={{ color: "red" }}>{message}</small>}
                                        />
                                    </Form.Group>
                                    <Form.Group as={Col} md={6} controlId="formGridDesc">
                                        <Form.Label>{t("groupsAndCustomized.groupName")}</Form.Label>
                                        <Controller
                                            control={control}
                                            name="groupName"
                                            rules={{ required: { value: true, message: "required" }, }}
                                            render={({ field }: any) => (
                                                <Form.Control
                                                    {...field}
                                                    aria-invalid={errors?.groupName ? "true" : ""}
                                                    variant="standard"
                                                    margin="normal"
                                                    autoComplete="off"
                                                    //onKeyPress={(e) => !/[0-9]/.test(e.key) && e.preventDefault()}
                                                    value={groupName}
                                                    onChange={(e: any) => { setGroupName(e.target.value); field.onChange(e); }}
                                                />
                                            )}
                                        />
                                        <ErrorMessage
                                            errors={errors}
                                            name="groupName"
                                            render={({ message }) => <small style={{ color: "red" }}>{message}</small>}
                                        />
                                    </Form.Group>
                                </Row>
                                <Row className="mb-3">    
                                    <Form.Group as={Col} md={6}>
                                        <Controller
                                            control={control}
                                            name="tarType"
                                            //rules={{ required: { value: true, message: "required" }, }}
                                            render={({ field }: any) => (
                                                <div className='d-flex align-items-center' style={{ height: "86px" }}>
                                                    <Form.Check
                                                        {...field}
                                                        type="radio"
                                                        label={t("groupsAndCustomized.tarTypeOpts.clientCommissioned")}
                                                        // name="customerType"
                                                        id="tarTypeRadios1"
                                                        onClick={() => { setTarType(1) }}
                                                        checked={tarType === 1}
                                                        className="col-md-6"
                                                    />
                                                    <Form.Check
                                                        {...field}
                                                        type="radio"
                                                        label={t("groupsAndCustomized.tarTypeOpts.clientNet")}
                                                        // name="customerType"
                                                        id="tarTypeRadios2"
                                                        onClick={() => { setTarType(2) }}
                                                        checked={tarType === 2}
                                                        className="col-md-6"
                                                    />
                                                </div>
                                            )}
                                        />
                                    </Form.Group>
                                    <Form.Group as={Col} md={3} controlId="formGridCat">
                                        <Form.Label>{t("groupsAndCustomized.comission")}</Form.Label>
                                        {/* <Form.Control autoComplete='off' type="text" /> */}
                                        <Controller
                                            control={control}
                                            name="comission"
                                            rules={{ required: { value: true, message: "required" }, }}
                                            render={({ field }: any) => (
                                                <Form.Control
                                                    {...field}
                                                    aria-invalid={errors?.comission ? "true" : ""}
                                                    variant="standard"
                                                    margin="normal"
                                                    autoComplete="off"
                                                    onChange={(e: any) => { setComission(e.target.value); field.onChange(e); }}
                                                    value={comission}
                                                    // onKeyPress={(e) => !/[0-9]/.test(e.key) && e.preventDefault()}
                                                />
                                            )}
                                        />
                                        <ErrorMessage
                                            errors={errors}
                                            name="comission"
                                            render={({ message }) => <small style={{ color: "red" }}>{message}</small>}
                                        />
                                    </Form.Group>
                                    <Form.Group as={Col} md={3} controlId="formGridCat">
                                        <Form.Label>{t("groupsAndCustomized.markup")}</Form.Label>
                                        {/* <Form.Control autoComplete='off' type="text" /> */}
                                        <Controller
                                            control={control}
                                            name="markup"
                                            rules={{ required: { value: true, message: "required" }, }}
                                            render={({ field }: any) => (
                                                <Form.Control
                                                    {...field}
                                                    aria-invalid={errors?.markup ? "true" : ""}
                                                    variant="standard"
                                                    margin="normal"
                                                    autoComplete="off"
                                                    onChange={(e: any) => { setMarkup(e.target.value); field.onChange(e); }}
                                                    value={markup}
                                                    // onKeyPress={(e) => !/[0-9]/.test(e.key) && e.preventDefault()}
                                                />
                                            )}
                                        />
                                        <ErrorMessage
                                            errors={errors}
                                            name="markup"
                                            render={({ message }) => <small style={{ color: "red" }}>{message}</small>}
                                        />
                                    </Form.Group>
                                </Row>
                                <Row className="mb-3">
                                    {/* Data da Abertura    Data do 1o Serviço    Moeda INVOICE(so "Real - BRL" por enquanto) */}
                                    <Form.Group as={Col} md={4}>
                                        <Form.Label>{t("groupsAndCustomized.dateCreated")}</Form.Label>
                                        <p>{formatDate(dateCreated)}</p>
                                    </Form.Group>
                                    <Form.Group as={Col} md={4}>
                                        <Form.Label>{t("groupsAndCustomized.firstServiceDate")}</Form.Label>
                                        <p>{formatDate(firstServiceDate)}</p>
                                    </Form.Group>
                                    <Form.Group as={Col} md={4}>
                                    <Form.Label>{t("groupsAndCustomized.currency")}</Form.Label>
                                        <Controller
                                            control={control}
                                            name="currency"
                                            rules={{ required: { value: true, message: 'Por favor, informe a moeda' } }}
                                            defaultValue={currency}
                                            render={({ field }: any) => (
                                                <div className={errors?.currency !== undefined ? "endpoint-error" : "endpoint"}>
                                                    <Form.Select
                                                        aria-labelledby="aria-label"
                                                        inputId="aria-example-input"
                                                        name="aria-live-color"
                                                        {...field}
                                                        as="select"
                                                        variant="standard"
                                                        margin="normal"
                                                        onChange={(e: any) => {setCurrency(e.target.value); field.onChange(e);}}
                                                        value={currency}
                                                    >
                                                        <option value="BRL">Real - BRL</option> 
                                                        {/* <option value="USD">Dolar - USD</option>  */}
                                                        {/* <option value="EUR">Euro - EUR</option>  */}
                                                    </Form.Select>
                                                </div>
                                            )} 
                                        />
                                        <ErrorMessage
                                            errors={errors}
                                            name="currency"
                                            render={({ message }) => <small style={{ color: "red" }}>{message}</small>}
                                        />
                                    </Form.Group>
                                </Row>
                                <Row className="mb-3">
                                    <Form.Group as={Col} md={4}>
                                        <Form.Label>{t("groupsAndCustomized.fileNo")}</Form.Label>
                                        <p>{fileNo}</p>
                                    </Form.Group>
                                    <Form.Group as={Col} md={4}>
                                        <Form.Label>{t("groupsAndCustomized.operationStatus")}</Form.Label>
                                        <p>{getOperationStatusText(operationStatus)}</p>
                                    </Form.Group>
                                    <Form.Group as={Col} md={4}>
                                        <Form.Label>{t("groupsAndCustomized.financialStatus")}</Form.Label>
                                        <p>{getFinancialStatusText(financialStatus)}</p>
                                    </Form.Group>
                                </Row>
                                <hr className="my-5" />
                                <h5 className="text-uppercase mb-3">{t("groupsAndCustomized.passengers.tableTitle")}</h5>
                                <Row>
                                    {/* tabela de passageiros */}
                                    <GroupFilePassengers
                                       action={getPassengers}
                                       info={arrayPassengers}
                                    />
                                </Row>
                                <hr className="my-5" />
                                <h5 className="text-uppercase mb-3">{t("groupsAndCustomized.services.tableTitle")}</h5>
                                <Row>
                                    {/* tabela de serviços */}
                                    <GroupFileServices
                                       action={getServices}
                                       info={arrayServices}
                                       totalPax={arrayPassengers.length}
                                       totalAdult={arrayPassengers.length}
                                       totalChild={0}
                                       totalInfant={0}
                                       markup={parseFloat(markup ? markup.replace(',', '.') : 0)}
                                    /> 
                                </Row>
                                <hr className="my-5" />
                                <Row className="mb-3">
                                    <Form.Group as={Col} md={3}>
                                        <Form.Label>{t("groupsAndCustomized.totalReceive")}</Form.Label>
                                        <p>{formatValue(totalReceive)}</p>
                                    </Form.Group>
                                    <Form.Group as={Col} md={3}>
                                        <Form.Label>{t("groupsAndCustomized.totalPay")}</Form.Label>
                                        <p>{formatValue(totalPay)}</p>
                                    </Form.Group>
                                    <Form.Group as={Col} md={3}>
                                        <Form.Label>{t("groupsAndCustomized.profitAmount")}</Form.Label>
                                        <p>{formatValue(profitAmount)}</p>
                                    </Form.Group>
                                    <Form.Group as={Col} md={3}>
                                        <Form.Label>{t("groupsAndCustomized.profitTax")}</Form.Label>
                                        <p>{formatValue(profitTax)}</p>
                                    </Form.Group>
                                </Row>
                                <Row className="mb-3">
                                    {/* A fatura será paga por? Valor Total; Serviço Discriminado. */}
                                    <Form.Group as={Col} md={6}>
                                        <Form.Label>{t("groupsAndCustomized.invoiceType")}</Form.Label>
                                        <Controller
                                            control={control}
                                            name="invoiceType"
                                            //rules={{ required: { value: true, message: "required" }, }}
                                            render={({ field }: any) => (
                                                <div className='d-flex align-items-center' style={{ height: "86px" }}>
                                                    <Form.Check
                                                        {...field}
                                                        type="radio"
                                                        label={t("groupsAndCustomized.invoiceTypeOpts.totalValue")}
                                                        // name="customerType"
                                                        id="invoiceTypeRadios1"
                                                        onClick={() => { setInvoiceType(1) }}
                                                        checked={invoiceType === 1}
                                                        className="col-md-6"
                                                    />
                                                    <Form.Check
                                                        {...field}
                                                        type="radio"
                                                        label={t("groupsAndCustomized.invoiceTypeOpts.serviceItemized")}
                                                        // name="customerType"
                                                        id="invoiceTypeRadios2"
                                                        onClick={() => { setInvoiceType(2) }}
                                                        checked={invoiceType === 2}
                                                        className="col-md-6"
                                                    />
                                                </div>
                                            )}
                                        />
                                    </Form.Group>
                                    {invoiceType === 1?  
                                    <Form.Group as={Col} md={2}>
                                        <Form.Label>{t("groupsAndCustomized.totalAmount")}</Form.Label>
                                        <Controller
                                            control={control}
                                            name="valor"
                                            rules={{ required: { value: true, message: "required" }, }}
                                            render={({ field }: any) => (
                                                <Form.Control
                                                    {...field}
                                                    aria-invalid={errors?.valor ? "true" : ""}
                                                    variant="standard"
                                                    margin="normal"
                                                    autoComplete="off"
                                                    //onKeyPress={(e) => !/[0-9]/.test(e.key) && e.preventDefault()}
                                                    value={totalAmount}
                                                    onChange={(e: any) => { setTotalAmount(e.target.value); }}
                                                />
                                            )}
                                        />
                                        <ErrorMessage
                                            errors={errors}
                                            name="valor"
                                            render={({ message }) => <small style={{ color: "red" }}>{message}</small>}
                                        />
                                    </Form.Group>
                                    : <></>}
                                </Row>
                                <Row className="d-flex justify-content-end mx-0 mt-5 mb-4">
                                    {disableButton === false?
                                    <Button type="submit" className="form-button">{t('groupsAndCustomized.buttonSave')}</Button>
                                    :
                                    <Button disabled={true}>{t('groupsAndCustomized.buttonSave')}</Button>
                                    }
                                </Row>
                            </Form>
                        </Container>
                    </Card.Body>
                </Accordion>
            </Card>

            <Modal
                className="modal-custom modalGroupFile modalGroupFilePayment"
                show={modalResponse !== null}
                onHide={() => {
                    // setCheckoutResponse('');
                    setModalContent('loading');
                    setModalResponse(null)
                    setDisableButton(false);
                    //if (paymentMethod !== '111') {
                    //    resetForm();
                    //}
                }}
                size="lg"
                aria-labelledby="contained-modal-title-vcenter"
                backdrop="static"
            >
                <>
                    {modalContent === "success" && <Modal.Header closeButton></Modal.Header>}
                    <Modal.Body>
                        <ModalContentPayment
                            //pix={['96', '100', '100', '111'].includes(paymentMethod)}
                            pix={true}
                            modalContent={modalContent}
                            checkoutResponse={checkoutResponse}
                            resetCart={resetForm}
                            setModalResponse={setModalResponse}
                            //getValues={getValues}
                            watchLanguage={'1'}
                        />

                    </Modal.Body>
                </>
            </Modal>
        </>
    );
}

export default GroupFileForm;