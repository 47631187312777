/* eslint-disable @typescript-eslint/no-unused-vars */
import React, { Key, useState } from "react";
import Table from "react-bootstrap/Table";
//import Button from "react-bootstrap/Button";

import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { useTranslation } from 'react-i18next';

//STYLES
import "./GroupFileServices.scss";
import { Form, InputGroup } from "react-bootstrap";

//JSON
// import objectSuppliers from "../SuppliersTableCompany/locales/suppliers.json";
//import ModalDefault from "../../../../../components/Modal/ModalDefault/ModalDefault";
//import FormAddFeeTerm from "../../../../../components/Forms/FormAddFeeTerm/FormAddFeeTerm";

import api from "../../../../services/api";

import SingleCalendarEditable from '../../../../components/Calendar/SingleCalendarEditable';
import GetResourcesByType from "../../../../components/C2Points/GetResourcesByType";
import GetSuppliersFilter from "../../../../components/C2Points/GetSuppliersByResource";
import SeachAllProduct from '../../../../components/C2Points/SeachAllProduct';
import SeachAllCustomized from "../../../../components/C2Points/SeachAllCustomized";

export interface propModal {
  action: any
  info: any
  totalPax: any
  totalAdult: any
  totalChild: any
  totalInfant: any
  markup: any
};

const GroupFileServices: React.FC<propModal> = ({
  action, info, totalPax, totalAdult, totalChild, totalInfant, markup
}: propModal) => {
  const { t } = useTranslation();

  /* function formatValue(cell: any) {
    //console.log(Math.sign(cell))
    if(cell && cell !== '') {
      return cell.toFixed(2).replace(".", ",");
    } else {
      return '0,00';
    }
} */

  // const suppliers = objectSuppliers.suppliers.default[0];

  // const [controlObject, setControlObject] = useState<any>(info ? info : [{
  //   id:0,name:'',passportId:'',email:'',phone:''
  // }]);
  const [controlObject, setControlObject] = useState<any>(info ? info.map((obj: any) => obj.id) : [-1]);

  const [arrayType       , setArrayType       ] = useState<any>(info ? info.map((obj: any) => `${obj.type}`  ) : ['']);
  const [arrayResourceId , setArrayResourceId ] = useState<any>(info ? info.map((obj: any) => `${obj.resourceId}`) : ['']);
  const [arrayProductCode, setArrayProductCode] = useState<any>(info ? info.map((obj: any) => obj.productCode) : ['']);
  const [arraySupplierId , setArraySupplierId ] = useState<any>(info ? info.map((obj: any) => `${obj.supplierId}`) : ['']);
  const [arrayGlobalPrice, setArrayGlobalPrice] = useState<any>(info ? info.map((obj: any) => `${obj.globalPrice}`) : ['']); 
  const [arrayNetAmount  , setArrayNetAmount  ] = useState<any>(info ? info.map((obj: any) => obj.netAmount  ) : ['']);
  const [arraySaleAmount , setArraySaleAmount ] = useState<any>(info ? info.map((obj: any) => obj.saleAmount ) : ['']);
  const [arrayServiceDate, setArrayServiceDate] = useState<any>(info ? info.map((obj: any) => obj.serviceDate) : ['']);
  const [arrayCity       , setArrayCity       ] = useState<any>(info ? info.map((obj: any) => obj.city       ) : ['']);
  const [arrayLanguage   , setArrayLanguage   ] = useState<any>(info ? info.map((obj: any) => `${obj.language}`) : ['']);
  const [arrayStatus     , setArrayStatus     ] = useState<any>(info ? info.map((obj: any) => obj.status     ) : ['']);

  const [deletedObjects, setDeletedObjects] = useState<any>([]);

  const handleActionModal = (objectModal: any) => {
    setControlObject([...controlObject, objectModal],);
  }

  // function removeRow(index: any) {

  //   const aux: any = [];
  //   const newDeleted : any = deletedObjects;

  //   for (var i: any = 0; i < controlObject.length; i++) {
  //     if (i !== index) {
  //       aux.push(controlObject[i]);
  //     } else {
  //       if (controlObject[i].id > 0) {
  //         newDeleted.push(controlObject[i].id);
  //         //[...deletedObjects, controlObject[i].id]
  //         setDeletedObjects(newDeleted);
  //       }
  //     }
  //   }

  //   setControlObject(aux);
  //   action(aux, deletedObjects);
  // }

  // function addRow() {
  //   var aux: any = {id:0,name:'',passportId:'',email:'',phone:''};
  //   controlObject.push(aux);
  //   action(controlObject, deletedObjects);
  // }

  function removeRow(index: any) {

    //console.log(controlObject)

    var aux: any = [];
    var auxType       : any = [];
    var auxResourceId : any = [];
    var auxProductCode: any = [];
    var auxSupplierId : any = [];
    var auxGlobalPrice: any = [];
    var auxNetAmount  : any = [];
    var auxSaleAmount : any = [];
    var auxServiceDate: any = [];
    var auxCity       : any = [];
    var auxLanguage   : any = [];
    var auxStatus     : any = [];

    for (var i: any = 0; i < controlObject.length; i++) {
      if (i !== index) {
        aux.push(controlObject[i]);
        
        auxType       .push(auxType       [i]);
        auxResourceId .push(auxResourceId [i]);
        auxProductCode.push(auxProductCode[i]);
        auxSupplierId .push(auxSupplierId [i]);
        auxGlobalPrice.push(auxGlobalPrice[i]);
        auxNetAmount  .push(auxNetAmount  [i]);
        auxSaleAmount .push(auxSaleAmount [i]);
        auxServiceDate.push(auxServiceDate[i]);
        auxCity       .push(auxCity       [i]);
        auxLanguage   .push(auxLanguage   [i]);
        auxStatus     .push(auxStatus     [i]);
      } else {
        if (controlObject[i] > 0) setDeletedObjects([...deletedObjects, controlObject[i]]);
      }
    }

    setArrayType       (auxType       );
    setArrayResourceId (auxResourceId );
    setArrayProductCode(auxProductCode);
    setArraySupplierId (auxSupplierId );
    setArrayGlobalPrice(auxGlobalPrice);
    setArrayNetAmount  (auxNetAmount  );
    setArraySaleAmount (auxSaleAmount );
    setArrayServiceDate(auxServiceDate);
    setArrayCity       (auxCity       );
    setArrayLanguage   (auxLanguage   );
    setArrayStatus     (auxStatus     );
    setControlObject(aux);
  }

  const addRow = () => {
    setArrayType       ([...arrayType       , '6']);
    setArrayResourceId ([...arrayResourceId , '']);
    setArrayProductCode([...arrayProductCode, '']);
    setArraySupplierId ([...arraySupplierId , '']);
    setArrayGlobalPrice([...arrayGlobalPrice, '1']);
    setArrayNetAmount  ([...arrayNetAmount  , '']);
    setArraySaleAmount ([...arraySaleAmount , '']);
    setArrayServiceDate([...arrayServiceDate, '']);
    setArrayCity       ([...arrayCity       , '']);
    setArrayLanguage   ([...arrayLanguage   , '1']);
    setArrayStatus     ([...arrayStatus     , '1']);
    setControlObject([...controlObject, -1]);
  };

  async function updateNetValue(index: any) {
    // se campo valor net for vazio
    if(arrayNetAmount[index] === "" && arraySupplierId[index] !== "" && arrayResourceId[index] !== "") {
      // pegar valor net do produto/recurso
      var defNetAmount = 0;
      
      if(arrayType[index] === '6') {
        // pega net customized
        defNetAmount = await getCustomizedNetAmount(arrayProductCode[index]);

      } else if(arrayType[index] === '7') {
        // pega net produto
        defNetAmount = await getProductNetAmount(arrayProductCode[index]);
      } else {
        // pega net recurso
        defNetAmount = await getResourceNetAmount(arraySupplierId[index], arrayType[index], arrayServiceDate[index], "", arrayResourceId[index], 0, totalPax, totalAdult, totalChild, totalInfant);
      }
      if(defNetAmount !== 0) {
        // gravar no campo do valor net
        arrayNetAmount[index] = defNetAmount.toFixed(2).replace(".", ",");
        
        // calcular o valor de venda
        if(arrayNetAmount[index] !== "") {
          const defSaleAmount = defNetAmount * (1 + (markup / 100));

          arraySaleAmount[index] = defSaleAmount.toFixed(2).replace(".", ",");
        }
      }
    }
    
  }

  async function getProductNetAmount(productCode: any) {
    return 0; // TODO
  }

  async function getCustomizedNetAmount(productCode: any) {
    const token = localStorage.getItem('GroupId') || '{}';
    const config = {
        headers: { 'Authorization': `Bearer ${token}`, },
    };

    try {
        const { data } = await api.get(`/GroupFile/GetCustomizedProduct/${productCode}`, config);
        if (data.status !== 400) {
            const obj = data.data;
            const amount = obj.netAmount;

            return amount;
        }
    } catch (error: any) {
        if (error?.response?.status === 401) { }
        if (error?.response?.status === 400) { }
    }
  }

  async function getResourceNetAmount(supplier: any, type : any, startTime: any, productCode: any, resourceId: any, categId: any, totalPax: any, totalAdult: any, totalChild: any, totalInfant: any) {
    const token = localStorage.getItem('GroupId') || '{}';
    const config = {
        headers: { 'Authorization': `Bearer ${token}`, },
    };

    try {
        const { data } = await api.post(`/Manifest/TryGetSupplierResourceTax`, {
            "supplierId": supplier,
            "resourceType": type,
            "tourDate": startTime,
            "productCode": productCode,
            "resourceId": resourceId,
            "categId": categId,
            "QtdePax": totalPax,
            "QtdeAdults": totalAdult,
            "QtdeChilds": totalChild,
            "QtdeInfants": totalInfant,
            "QtdeElders": 0
        }, config);
        if (data.status !== 400) {
            const obj = data.data;
            const amount = obj.data;

            return amount;
        }
    } catch (error: any) {
        if (error?.response?.status === 401) { }
        if (error?.response?.status === 400) { }
    }
  }

  function refreshBlocks() {
    const blocks: any = [];

    for (let i: any = 0; i < controlObject.length; i++) {
      const block = {
        id: controlObject[i],
        type       : arrayType       [i],
        resourceId : arrayResourceId [i],
        productCode: arrayProductCode[i],
        supplierId : arraySupplierId [i],
        globalPrice: arrayGlobalPrice[i],
        netAmount  : arrayNetAmount  [i],
        saleAmount : arraySaleAmount [i],
        serviceDate: arrayServiceDate[i],
        city       : arrayCity       [i],
        language   : arrayLanguage   [i],
        status     : arrayStatus     [i],
      };
      blocks.push(block);
    }

    action(blocks, deletedObjects);
  }

  const fixDate = (data: any) => {
    if (!data) return '';
    var aux = data.split('/');

    return (`${aux[2]}-${aux[1]}-${aux[0]}`);
  }

  function formatDate(cell: any) {
    if (!cell) return '';
    //var date = convertUTCDateToLocalDate(new Date(cell));
    var aux: any = cell.split("T")[0].split("-");
    var date: any = `${aux[2]}/${aux[1]}/${aux[0]}`;
    return date;
  }

  return (
    <>
      <div className="table-add-default groupfile-service-table">
        <div className="table-container">
          {/* <BootstrapTable rowEvents={ rowEvents } bootstrap4 keyField='email' data={controlObject} hover={true} columns={columns} pagination={pagination} striped={true} /> */}
          <Table striped bordered>
            <thead>
              <tr>
                <th>{t("groupsAndCustomized.services.type")}</th>
                <th>{t("groupsAndCustomized.services.resourceProduct")}</th>
                <th>{t("groupsAndCustomized.services.supplier")}</th>
                <th>{t("groupsAndCustomized.services.globalPrice")}</th>
                <th>{t("groupsAndCustomized.services.netAmount")}</th>
                <th>{t("groupsAndCustomized.services.saleAmount")}</th>
                <th>{t("groupsAndCustomized.services.serviceDate")}</th>
                <th>{t("groupsAndCustomized.services.city")}</th>
                <th>{t("groupsAndCustomized.services.language")}</th>
                <th>{t("groupsAndCustomized.services.status")}</th>
                <th>{t("groupsAndCustomized.services.actions")}</th>
              </tr>
            </thead>
            <tbody>
              {controlObject.length > 0
                ? controlObject.map((item: any, index: Key) => (
                  <tr key={index}>
                    <td>
                      {/* type */}
                      {item > 0 ? <p>{info ? info.serviceTypeName : ''}</p> 
                      :
                      <Form.Select
                        aria-labelledby="aria-label"
                        name="serviceType"
                        as="select"
                        onChange={(e: any) => {arrayType[index] = e.target.value; refreshBlocks(); }}
                        value={arrayType[index]}
                      >
                        <option value={1}>{t("groupsAndCustomized.services.typeOpts.vehicle")}</option> 
                        <option value={2}>{t("groupsAndCustomized.services.typeOpts.driver")}</option> 
                        <option value={3}>{t("groupsAndCustomized.services.typeOpts.guide")}</option> 
                        <option value={4}>{t("groupsAndCustomized.services.typeOpts.ticket")}</option> 
                        <option value={5}>{t("groupsAndCustomized.services.typeOpts.restaurant")}</option> 
                        <option value={6}>{t("groupsAndCustomized.services.typeOpts.other")}</option> 
                        <option value={7}>{t("groupsAndCustomized.services.typeOpts.product")}</option> 
                      </Form.Select>
                      }
                    </td>
                    <td style={{textAlign: 'left'}}>
                      {/* resource/product */}
                      {item > 0 ? <p>{info ? info.serviceName : ''}</p>
                      :
                       (arrayType[index] === '6'? 
                        <SeachAllCustomized setCode={(code: any) => {arrayProductCode[index] = code; updateNetValue(index); refreshBlocks(); }} />
                        : arrayType[index] === '7' ?
                        <SeachAllProduct setCode={(code: any) => {arrayProductCode[index] = code; updateNetValue(index); refreshBlocks(); }} />
                        : <GetResourcesByType resourceType={arrayType[index]} setResourceId={(resourceId: any) => {arrayResourceId[index] = resourceId; updateNetValue(index); refreshBlocks(); }} />
                        )
                      }
                    </td>
                    <td>
                      {/* supplier */}
                      {item > 0 ? <p>{info ? info.supplierName : ''}</p>
                      :
                      <GetSuppliersFilter
                        supplier={arraySupplierId[index]}
                        setSupplier={(supplier: any) => {arraySupplierId[index] = supplier; refreshBlocks(); }}
                      />
                      }
                    </td>
                    <td>
                      {/* global price */}
                      {item > 0 ? <p>{info ? info.globalPriceDesc : ''}</p> 
                      :
                      <Form.Select
                        aria-labelledby="aria-label"
                        name="globalPrice"
                        as="select"
                        onChange={(e: any) => {arrayGlobalPrice[index] = e.target.value; refreshBlocks(); }}
                        value={arrayGlobalPrice[index]}
                      >
                        <option value={1}>{t("groupsAndCustomized.services.globalPriceOpts.singlePrice")}</option> 
                        <option value={2}>{t("groupsAndCustomized.services.globalPriceOpts.priceByPax")}</option> 
                      </Form.Select>
                      }
                    </td>
                    <td>
                      {/* net amount */}
                      <Form.Control type="text"
                        autoComplete='off'
                        required
                        defaultValue={arrayNetAmount[index]}
                        onChange={(e: any) => { arrayNetAmount[index] = e.target.value; refreshBlocks(); }}
                        value={arrayNetAmount[index]}
                      />
                    </td>
                    <td>
                      {/* sale amount */}
                      <Form.Control type="text"
                        autoComplete='off'
                        required
                        defaultValue={arraySaleAmount[index]}
                        onChange={(e: any) => { arraySaleAmount[index] = e.target.value; refreshBlocks(); }}
                        value={arraySaleAmount[index]}
                      />
                    </td>
                    <td>
                      {/* service date */}
                      <InputGroup
                        hasValidation
                        //className={` ${erroData === true ? "endpoint-error" : ""}`} 
                        size="sm"
                        placeholder="Quando?"
                      >
                        <SingleCalendarEditable setValue={(date: any) => { arrayServiceDate[index] = fixDate(date); refreshBlocks(); }} date={formatDate(arrayServiceDate[index])} />
                      </InputGroup>
                    </td>
                    <td>
                      {/* city */}
                      <Form.Control type="text"
                        autoComplete='off'
                        required
                        defaultValue={arrayCity[index]}
                        onChange={(e: any) => { arrayCity[index] = e.target.value; refreshBlocks(); }}
                        value={arrayCity[index]}
                      />
                    </td>
                    <td>
                      {/* language */}
                      {item > 0 ? <p>{info ? info.languageDesc : ''}</p> 
                      :
                      <Form.Select
                        aria-labelledby="aria-label"
                        name="language"
                        as="select"
                        onChange={(e: any) => {arrayLanguage[index] = e.target.value; refreshBlocks(); }}
                        value={arrayLanguage[index]}
                      >
                        <option value={1}>{t("groupsAndCustomized.services.languageOpts.portuguese")}</option> 
                        <option value={2}>{t("groupsAndCustomized.services.languageOpts.english")}</option> 
                        <option value={3}>{t("groupsAndCustomized.services.languageOpts.spanish")}</option> 
                        <option value={4}>{t("groupsAndCustomized.services.languageOpts.italian")}</option>  
                        <option value={5}>{t("groupsAndCustomized.services.languageOpts.french")}</option> 
                        <option value={6}>{t("groupsAndCustomized.services.languageOpts.deutsch")}</option>
                        <option value={7}>{t("groupsAndCustomized.services.languageOpts.corean")}</option>
                        <option value={8}>{t("groupsAndCustomized.services.languageOpts.mandarin")}</option>
                      </Form.Select>
                      }
                    </td>
                    <td>
                      {/* status */}
                      {item > 0 ? <p>{info ? info.statusDesc : ''}</p> 
                      :
                      <Form.Select
                        aria-labelledby="aria-label"
                        name="status"
                        as="select"
                        onChange={(e: any) => {arrayStatus[index] = e.target.value; refreshBlocks(); }}
                        value={arrayStatus[index]}
                      >
                        <option value={1}>{t("groupsAndCustomized.services.statusOpts.pending")}</option> 
                        <option value={2}>{t("groupsAndCustomized.services.statusOpts.request")}</option> 
                        <option value={3}>{t("groupsAndCustomized.services.statusOpts.confirmed")}</option> 
                      </Form.Select>
                      }
                    </td>
                    <td>{
                      <div className="center-table options-table">
                        <div className="px-2">
                          <div className="d-flex justify-content-end">
                            <span className='btn' style={{ height: "40px" }} onClick={(e: any) => removeRow(index)}>
                              <FontAwesomeIcon
                                size="lg"
                                icon={["fal", "trash-alt"]}
                                style={{ color: "#707070" }}
                              />
                            </span>
                          </div>
                        </div>
                        {/* <Dropdown drop="down" >
                          <Dropdown.Toggle variant="light ">
                            <FontAwesomeIcon
                              size="lg"
                              icon={['fal', 'ellipsis-h']}
                            />
                          </Dropdown.Toggle>

                          <Dropdown.Menu>
                            <Dropdown.Item
                              onClick={() => editRow(index)}
                              eventKey="1"
                            >
                              <FontAwesomeIcon
                                icon={['fal', 'edit']}
                              //className="mr-2"
                              />
                              {t('suppliersTable.edit')}
                            </Dropdown.Item>
                            <Dropdown.Item
                              onClick={() => removeRow(index)}
                              eventKey="2"
                              className="text-danger removeRow">
                              <FontAwesomeIcon
                                icon={['fal', 'trash-alt']}
                                //className="mr-2"
                              />
                              {t('suppliersTable.remove')}
                            </Dropdown.Item>
                          </Dropdown.Menu>
                        </Dropdown> */}
                      </div>
                    }</td>
                  </tr>
                ))
                : ""
              }
              <div className="add-contact">
                <div className="text-center btn-add-contact">
                  <span className='btn' style={{ height: "40px" }} onClick={(e: any) => addRow()}>
                    <FontAwesomeIcon
                      size="lg"
                      icon={["fal", "plus"]}
                      style={{ color: "#707070", margin: "0 12px" }}
                    />
                  </span>
                  {/* <ModalDefault
                    title="Taxa e Prazo"
                    classModal="modal-pos-tax"
                    name={'+'}
                    class="btn-add"
                  >
                    <div className="form-add-contact">
                      <FormAddFeeTerm action={handleActionModal} info={null} />
                    </div>
                  </ModalDefault> */}
                </div>
              </div>
            </tbody>
          </Table>

          {/* {
            feeTermEdit !== null
              ?
              <Modal
                className="modal-custom modalAuth modal-pos-tax"
                show={feeTermModalShow}
                onHide={() => setFeeTermModalShow(false)}
                size="lg"
                aria-labelledby="contained-modal-title-vcenter"
              >
                <>
                  <Modal.Header className="p-3" closeButton>
                    <h3>Taxa e Prazo</h3>
                  </Modal.Header>
                  <hr style={{ margin: "30px 0" }} />
                  <FormAddFeeTerm action={handleActionEditModal} info={feeTermEdit} />
                </>
              </Modal>
              :
              <></>
          } */}

        </div>
      </div>
    </>
  );
}

export default GroupFileServices;
