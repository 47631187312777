import React, { useEffect, useState } from 'react';
import { Row, Col, Card } from "react-bootstrap";

import Form from "react-bootstrap/Form";
import { Controller } from "react-hook-form";
import { ErrorMessage } from "@hookform/error-message";
import Select from "react-select";
import PaymentCheckoutCredit from './PaymentCheckoutCredit';
import PaymentCheckoutCreditOffline from './PaymentCheckoutCreditOffline';
import PaymentCheckoutBilled from './PaymentCheckoutBilled';
import PaymentCheckoutDebit from './PaymentCheckoutDebit';
import PaymentCheckoutPix from './PaymentCheckoutPix';
import PaymentCheckoutDirect from './PaymentCheckoutDirect';
import PaymentCheckoutCash from './PaymentCheckoutCash';
import PaymentCheckoutBankTransfer from './PaymentCheckoutBankTransfer';
import PaymentCheckoutPicpay from './PaymentCheckoutPicpay';
import PaymentCheckoutStripeQrCode from './PaymentCheckoutStripeQrCode';

export interface propForm {
    control: any,
    errors: any,
    setValue: any,
    action: any,
    isForeign: any,
    getValues: any,
    watchCountry?: any;    
    t?: any
};

const PaymentCheckout: React.FC<propForm> = ({
    control, errors, setValue, action, watchCountry, isForeign, getValues, t
}: propForm) => {
    const c2tourAuth: any = JSON.parse(localStorage.getItem("c2tourAuth") || "{}");

    const level = atob(
        JSON.parse(localStorage.getItem("c2tourAuth") || "{}").user.perfil.nivel
    );

    const [paymentType, setPaymentType] = useState<any>(process.env.REACT_APP_CLIENT_NAME !== 'Angra' ? "credit" : "cOffiline");

    useEffect(()=>{
        setValue('city','');
        setValue('state','');
    // eslint-disable-next-line react-hooks/exhaustive-deps
    },[paymentType])

    function handleChangePaymentOption(e: any) {
        action(e.id, e.merchantPayMethod)
        setPaymentType(e.value)
        setValue('paymentOptions', e.value);
    }

    const admnistrador: any = process.env.REACT_APP_CLIENT_NAME !== 'Angra' ? [
        { value: "credit", label: t("checkout.paymentCheckout.dropdownPayment.creditCard"), id: '1' , merchantPayMethod: '1'},
        { value: "cOffiline", label: t("checkout.paymentCheckout.dropdownPayment.offlineCreditCard"), id: '1', merchantPayMethod: '0' },
        { value: "billed", label: t("checkout.paymentCheckout.dropdownPayment.invoicedSales"), id: '85', merchantPayMethod: '0' },
        { value: "pix", label: t("checkout.paymentCheckout.dropdownPayment.pix"), id: '96', merchantPayMethod: '2' },
        //{ value: "pixNet", label: t("checkout.paymentCheckout.dropdownPayment.commissionFreePix"), id: '100', merchantPayMethod: '2' },
        { value: "cash", label: t("checkout.paymentCheckout.dropdownPayment.money"), id: '2', merchantPayMethod: '0'  },
        { value: "picpay", label: t("checkout.paymentCheckout.dropdownPayment.picpay"), id: '110', merchantPayMethod: '8'  },
        { value: "link", label: t("checkout.paymentCheckout.dropdownPayment.externalPayment"), id: '111', merchantPayMethod: '0'  },
    ] : [
        { value: "cOffiline", label: t("checkout.paymentCheckout.dropdownPayment.offlineCreditCard"), id: '1', merchantPayMethod: '0' },
        { value: "billed", label: t("checkout.paymentCheckout.dropdownPayment.invoicedSales"), id: '85', merchantPayMethod: '0' },
        { value: "cash", label: t("checkout.paymentCheckout.dropdownPayment.money"), id: '2', merchantPayMethod: '0'  },
    ]

    const agent: any = [
        { value: "credit", label: t("checkout.paymentCheckout.dropdownPayment.creditCard"), id: '1' , merchantPayMethod: '1'},
        { value: "pix", label: t("checkout.paymentCheckout.dropdownPayment.pix"), id: '96', merchantPayMethod: '2' },
        { value: "link", label: t("checkout.paymentCheckout.dropdownPayment.externalPayment"), id: '111', merchantPayMethod: '0'  },
    ]

    const agentWithBills: any = process.env.REACT_APP_CLIENT_NAME !== 'Angra' ? [
        { value: "credit", label: t("checkout.paymentCheckout.dropdownPayment.creditCard"), id: '1' , merchantPayMethod: '1'},
        { value: "pix", label: t("checkout.paymentCheckout.dropdownPayment.pix"), id: '96', merchantPayMethod: '2' },
        //{ value: "pixNet", label: t("checkout.paymentCheckout.dropdownPayment.commissionFreePix"), id: '100', merchantPayMethod: '2' },
        { value: "billed", label: t("checkout.paymentCheckout.dropdownPayment.invoicedSales"), id: '85', merchantPayMethod: '0' },
        { value: "link", label: t("checkout.paymentCheckout.dropdownPayment.externalPayment"), id: '111', merchantPayMethod: '0'  },
    ] : [
        { value: "billed", label: t("checkout.paymentCheckout.dropdownPayment.invoicedSales"), id: '85', merchantPayMethod: '0' },
    ]

    const agentIsNotCommission: any = [
        { value: "credit", label: t("checkout.paymentCheckout.dropdownPayment.creditCard"), id: '1' , merchantPayMethod: '1'},
        { value: "pix", label: t("checkout.paymentCheckout.dropdownPayment.pix"), id: '96', merchantPayMethod: '2' },
        { value: "link", label: t("checkout.paymentCheckout.dropdownPayment.externalPayment"), id: '111', merchantPayMethod: '0'  },
    ]

    enum paymentTypeSelected {
        "credit" = <PaymentCheckoutCredit control={control} errors={errors} setValue={setValue} isForeign={isForeign} getValues={getValues} t={t} watchCountry={watchCountry} /> as any,
        "cOffiline" = <PaymentCheckoutCreditOffline control={control} errors={errors} setValue={setValue} isForeign={isForeign} t={t} /> as any,
        "billed" = <PaymentCheckoutBilled control={control} errors={errors} /> as any,
        "debit" = <PaymentCheckoutDebit control={control} errors={errors} setValue={setValue} t={t} /> as any,
        "pix" = <PaymentCheckoutPix control={control} errors={errors} t={t} /> as any,
        "direct" = <PaymentCheckoutDirect control={control} errors={errors} setValue={setValue} t={t} /> as any,
        "cash" = <PaymentCheckoutCash control={control} errors={errors} t={t} /> as any,
        "bankTransfer" = <PaymentCheckoutBankTransfer control={control} errors={errors} t={t} /> as any,
        "picpay" = <PaymentCheckoutPicpay control={control} errors={errors} t={t} /> as any,
        "link" = <PaymentCheckoutStripeQrCode control={control} errors={errors} t={t} /> as any,
    }

    const paymentOption = c2tourAuth.user.perfil.isAgent === 0 && level !== '29' ? [...admnistrador] : c2tourAuth.user.perfil.canSeeBills === true ? [...agentWithBills] : level === '29' ? [...agentIsNotCommission] : [...agent];

    return (
        <>
            <h2 className='payment-title'>{t("checkout.paymentCheckout.payment")}</h2>

            {/* <Form noValidate onSubmit={handleSubmit(onSubmit)} autoComplete="false"> */}
            <Card className="card-unit bg-white mt-4">
                <Card.Body>
                    <div className="card-title">
                        <h4>{t("checkout.paymentCheckout.makePayment")}</h4>
                    </div>

                    <Row className="mb-3">
                        <Form.Group as={Col} md="4" controlId="formCheckoutPaymentOptions">
                            <Form.Label>{t("checkout.paymentCheckout.options")}</Form.Label>
                            <Controller
                                control={control}
                                name="paymentOptions"
                                /* rules={{ required: { value: true, message: 'Por favor, informe o campo.' } }} */
                                render={({ field }: any) => (
                                    <div className="endpoint">
                                        <Select
                                            placeholder="Selecione"
                                            onChange={handleChangePaymentOption}
                                            // value={paymentType}
                                            defaultValue={paymentOption[0]}
                                            options={paymentOption}
                                        />
                                    </div>
                                )}
                            />
                            <ErrorMessage
                                errors={errors}
                                name="paymentOptions"
                                render={({ message }) => <small style={{ color: "red" }}>{message}</small>}
                            />
                        </Form.Group>
                    </Row>


                    {paymentTypeSelected[paymentType]}

                    <hr />
                </Card.Body>
            </Card>
        </>
    );
}

export default PaymentCheckout;